html {
  &.content_fixed {
    overflow: hidden;
  }
}

.h_wrap {
  width: 100%;
  height: 120px;
  position: relative;
  z-index: 20;
  a {
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      opacity: 1;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    fill: #000;
  }
  // =================================================================================
  // ヘッダー：PC
  // =================================================================================
  .h_pc {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    .h_blk_left {
      .menu_trigger {
        width: 30px;
        height: 35px;
        position: absolute;
        top: 25px;
        left: 30px;
        z-index: 10;
        cursor: pointer;
        span {
          display: block;
          width: 100%;
          height: 5px;
          background-color: #000;
          border-radius: 50px;
          position: absolute;
          left: 0;
          transition-duration: .2s;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 15px;
          }
          &:nth-of-type(3) {
            top: 30px;
          }
        }
        &:hover {
          span {
            &:nth-of-type(1) {
              transform: translateY(3px);
            }
            &:nth-of-type(3) {
              transform: translateY(-3px);
            }
          }
        }
      }
      .h_logo {
        width: 150px;
        height: 40px;
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        a {
          display: block;
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%
          }
        }
      }
    }
    .h_menu {
      width: 100%;
      margin: 0 auto;
      transform: translateY(67px);
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        transition-duration: 0s;
      }
      nav {
        display: inline-block;
        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          font-size: 1.8rem;
          font-weight: 500;
          color: #000;
          letter-spacing: .1em;
          li {
            a {
              line-height: 53px;
              padding: 0 33px;
              position: relative;
            }
            //ホバー時の処理
            &:hover {
              a {
                color: #c02d28 !important;
              }
              svg {
                fill: #c02d28 !important;
              }
            }
            &.open {
              a {
                color: #c02d28 !important;
                &::after {
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 11px 5.5px 0 5.5px;
                  border-color: #c02d28 transparent transparent transparent;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                }
              }
              svg {
                fill: #c02d28 !important;
              }
            }
          }
          .menu_search {
            width: 85px;
            height: 53px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            a {
              display: block;
              width: 100%;
              height: 100%;
              padding: 0;
              line-height: 53px;
              svg {
                display: block;
                width: 25px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
    .h_blk_right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 25px;
      right: 22px;
      .h_member_wrap {
        display: flex;
        align-items: center;
        margin-right: 26px;
        .member_name_blk {
          font-size: 1.4rem;
          color: #000;
          font-weight: bold;
          letter-spacing: .025em;
          margin-right: 18px;
        }
        .h_member {
          width: 20px;
          height: 22px;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: auto;
            }
            &:hover {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_favorite_wrap {
        width: 23px;
        height: 21px;
        margin-right: 23px;
        .h_favorite {
          width: 100%;
          height: 100%;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: none;
            }
            &:hover {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_cart_wrap {
        width: 26px;
        height: 22.5px;
        margin-right: 30px;
        .h_cart {
          width: 100%;
          height: 100%;
          position: relative;
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
          .in_cart_item_num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background-color: #c02d28;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            line-height: 1;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            right: -10px;
            pointer-events: none;
          }
        }
      }
      .h_resno_wrap {
        width: 137px;
        height: 35px;
        border-left: 2px solid #000;
        padding-left: 17px;
        .h_resno {
          width: 100%;
          height: 100%;
          a {
            display: block;
            width: 100%;
            height: 100%;
            svg {
              fill: #0086a2;
            }
          }
        }
      }
    }
  }

  // =================================================================================
  // ヘッダー：SP
  // =================================================================================
  .h_sp {
    display: none;
  }

  // =================================================================================
  // 追従メニュー
  // =================================================================================
  &.h_fixed {
    height: 82px;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition-duration: .2s;
    z-index: 210;
    .h_blk_left {
      .h_logo {
        top: 22px;
        left: 84px;
        margin: 0;
      }
    }
    .h_menu {
      transform: translateY(0);
      nav {
        ul {
          li {
            a {
              line-height: 82px;
            }
          }
          .menu_search {
            height: 82px;
            a {
              line-height: 82px;
            }
          }
        }
      }
    }
    .h_blk_right {
      top: 0;
      right: 0;
      height: 82px;
      .h_member_wrap {
        display: block;
        margin-right: 36px;
        .member_name_blk {
          display: none;
        }
        .h_member {
        }
      }
      .h_favorite_wrap {
        margin-right: 33px;
      }
      .h_cart_wrap {
        margin-right: 35px;
      }
      .h_resno_wrap {
        width: 160px;
        height: 35px;
        background-color: transparent;
        padding: 0;
        // border-left: none !important;
        &:hover {
          // border-left: none !important;
        }
        .h_resno {
          width: 100%;
          height: 100%;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              width: 118px;
              height: 35px;
              fill: #0086a2 !important;
            }
          }
        }
      }
    }
    &.viewed {
      transform: translateY(0);
    }
  }

  // =================================================================================
  // ログイン時の処理
  // =================================================================================
  &.logged_in {
    .h_inner {
      .h_blk_right {
        .h_member_wrap {
          .h_member {
            .popup_trigger {
              a {
                pointer-events: none;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .h_favorite_wrap {
          .h_favorite {
            .popup_trigger {
              a {
                pointer-events: auto;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// =================================================================================
// 会員系メニュー：ポップアップ
// =================================================================================
.h_wrap {
  .h_member_popup {
    display: none;
    width: 150px;
    position: absolute;
    top: 54px;
    right: 222px;
    padding-top: 21px;
    transition-duration: .2s;
    ul {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2.85;
      color: #fff;
      background-color: #515151;
      padding: 18px 28px;
      border-radius: 5px;
    }
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 12px 7px;
      border-color: transparent transparent #515151 transparent;
      position: absolute;
      top: 9px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &.viewed {
      display: block;
    }
  }

  .h_favorite_popup {
    display: none;
    width: 170px;
    position: absolute;
    top: 54px;
    right: 164px;
    padding-top: 21px;
    .popup_favorite_inner {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.71;
      color: #fff;
      background-color: #515151;
      padding: 24px 18px 40px;
      border-radius: 5px;
      p {
        a {
          display: inline;
        }
      }
    }
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 12px 7px;
      border-color: transparent transparent #515151 transparent;
      position: absolute;
      top: 9px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &.viewed {
      display: block;
    }
  }
}
.h_fixed {
  .h_member_popup {
    top: 52px;
    right: 248px;
  }

  .h_favorite_popup {
    top: 52px;
    right: 180px;
  }
}

// =================================================================================
// ヘッダー：スライドダウンメニュー
// =================================================================================
.h_slidedown_menu {
  width: 100%;
  height: 0;
  background-color: #fff;
  position: relative;
  z-index: -1;
  transition-duration: .2s;
  overflow: hidden;
  .h_slidedown_wrap {
    width: 100%;
    height: 100%;
    .h_slidedown_sec {
      width: 100%;
      height: 100%;
      padding-top: 40px;
      display: none;
      overflow: hidden;
      &.viewed {
        display: block;
      }
      &.menu_products,
      &.menu_support {
        .h_slidedown_blk {
          display: flex;
          justify-content: center;
          .h_slidedown_inner {
            padding: 0 40px;
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 1px;
              height: 180px;
              background-color: #bfbfbf;
              position: absolute;
              top: 36px;
              right: 0;
            }
            &:last-of-type {
              &::after {
                content: none;
              }
            }
            h3 {
              font-size: 1.7rem;
              font-weight: bold;
              margin-bottom: 15px;
            }
            ul {
              &.col_2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                max-width: 260px;
                li {
                  width: 48%;
                  &:nth-of-type(odd) {
                    margin-right: 4%;
                  }
                }
              }
              li {
                margin-bottom: .8em;
                &.menu_to_list {
                  margin-top: 2.4em;
                }
                a {
                  font-size: 1.4rem;
                  line-height: 1.4;
                  transition-duration: 0s;
                  &:hover {
                    color: #c02d28;
                  }
                }
              }
            }
            // &.inner_other {
            //   ul {
            //     li {
            //       font-weight: bold;
            //       margin-bottom: 1.6em;
            //       &.menu_to_list {
            //         margin-top: 5em;
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      &.menu_about,
      &.menu_contents {
        .h_slidedown_blk {
          display: flex;
          justify-content: center;
          .h_slidedown_inner {
            width: 280px;
            margin: 0 40px;
            a {
              display: block;
              h3 {
                font-size: 1.7rem;
                margin-bottom: 8px;
              }
              div {
                width: 100%;
                margin-bottom: 12px;
                transition-duration: .2s;
                img {
                  display: block;
                  width: 100%;
                }
              }
              p {
                font-size: 1.4rem;
              }
              &:hover {
                h3 {
                  color: #c02d28;
                }
                div {
                  opacity: .7;
                }
              }
            }
          }
        }
      }
      &.menu_search {
        .h_slidedown_blk {
          .h_search_blk {
            width: 708px;
            margin: 40px auto 0;
            form {
              display: block;
            }
            .search_inner {
              display: flex;
              .search_input {
                width: 544px;
                border-bottom: 1px solid #8E8A8C;
                padding-left: 10px;
                input[type="text"] {
                  width: 100%;
                  height: 47px;
                  padding: 0 26px 0 0;
                  line-height: 47px;
                  font-size: 1.6rem;
                  outline: none;
                  margin-bottom: 10px;
                }
              }
              .search_btn {
                width: 160px;
                height: 50px;
                margin-left: 4px;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 25px;
                  height: 25px;
                  background-image: url(../../img/common/ico_search_w.png);
                  background-position: center center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 14px;
                  left: 28px;
                }
                input[type="submit"] {
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  border: 1px solid #000;
                  color: #fff;
                  font-weight: bold;
                  font-size: 1.6rem;
                  padding-left: 28px;
                }
              }
            }
          }
          .h_keyword {
            width: 708px;
            margin: 40px auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
              margin-right: 15px;
              margin-bottom: 15px;
              a {
                height: 32px;
                line-height: 32px;
                border: 1px solid #000;
                padding: 0 16px;
                font-size: 1.4rem;
                font-weight: bold;
                &::before {
                  content: "#";
                  margin-right: .2em;
                }
                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  &.open {
    height: 360px;
    z-index: 1;
  }
}

// =================================================================================
// スライドライト（ハンバーガー）メニュー
// =================================================================================
.h_slideright_menu {
  position: relative;
  z-index: 10;
  .h_slideright_menu_inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 520px;
    height: 100vh;
    background-color: #fff;
    z-index: 20;
    transform: translateX(-101%);
    transition-duration: .2s;
    overflow-y: scroll;
    padding-bottom: 200px;
    .btn_close {
      width: 30px;
      height: 35px;
      position: absolute;
      top: 25px;
      left: 30px;
      z-index: 20;
      cursor: pointer;
      span {
        display: block;
        width: 100%;
        height: 5px;
        background-color: #000;
        border-radius: 50px;
        position: absolute;
        left: 0;
        transition-duration: .2s;
        &:nth-of-type(1) {
          top: 15px;
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: translateX(-230%);
        }
        &:nth-of-type(3) {
          top: 15px;
          transform: rotate(45deg);
        }
      }
    }
    .slideright_menu_top {
      width: 100%;
      padding-top: 80px;
      .slideright_menu_1st {
        ul {
          li {
            width: 100%;
            border-top: 1px solid #bbb;
            a {
              display: block;
              width: 100%;
              height: 50px;
              padding: 0 25px 0 30px;
              font-size: 1.6rem;
              letter-spacing: .1em;
              line-height: 50px;
              font-weight: bold;
              position: relative;
              &::after {
                transition-duration: .2s;
              }
            }
            &.reg_link {
              a {
                &::after {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 13px;
                  background-image: url(../../img/common/arrow_up_b.png);
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center center;
                  transform: rotate(90deg);
                  position: absolute;
                  top: calc(50% - 6.5px);
                  right: 25px;
                }
              }
            }
            &.toggle_link {
              > a {
                &::after {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 13px;
                  background-image: url(../../img/common/arrow_up_b.png);
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center center;
                  transform: rotate(180deg);
                  position: absolute;
                  top: calc(50% - 6.5px);
                  right: 25px;
                }
                &.open {
                  &::after {
                    transform: rotate(0deg);
                  }
                }
              }
            }
            &.menu_colantotte {
              a {
                span {
                  width: 74px;
                  height: 20px;
                  display: inline-block;
                  margin-left: 18px;
                  position: relative;
                  top: 3px;
                  svg {
                    fill: #000 !important;
                  }
                }
              }
            }
            &.menu_resno {
              a {
                background-color: #0086a2;
                color: #fff;
                &::after {
                  background-image: url(../../img/common/arrow_up_w.png);
                }
                span {
                  width: 68px;
                  height: 20px;
                  display: inline-block;
                  margin-left: 10px;
                  position: relative;
                  top: 3px;
                  svg {
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
      }
      .slideright_menu_2nd {
        display: none;
        ul {
          li {
            a {
              padding-left: 48px;
              &.open {
                background-color: #eeeeee;
                &::after {
                  transform: rotate(0deg);
                }
              }
            }
          }
        }
      }
      .slideright_menu_3rd {
        display: none;
        ul {
          background-color: #515151;
          display: flex;
          flex-wrap: wrap;
          padding: 12px 0;
          li {
            width: 50%;
            border-top: none;
            a {
              height: auto;
              color: #fff;
              font-size: 1.4rem;
              font-weight: normal;
              line-height: 1.4;
              padding: 10px 25px 10px 46px;
            }
          }
        }
      }
    }
    .slideright_menu_bottom {
      padding: 26px 0;
      .slideright_menu_bottom_blk {
        display: flex;
        flex-wrap: wrap;
        padding-left: 30px;
        margin-bottom: 24px;
        li {
          width: 210px;
          font-size: 14px;
          line-height: 2;
          padding-right: 10px;
        }
        &:last-of-type {
          display: block;
          li {
            width: 180px;
            height: 40px;
            padding-right: 0;
            a {
              width: 100%;
              height: 100%;
              text-align: center;
              border: 1px solid #000;
              line-height: 38px;
              font-weight: bold;
              &:hover {
                color: #fff;
                background-color: #000;
              }
            }
          }
        }
      }
    }
  }
  .h_slideright_menu_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.3);
    z-index: 10;
    display: none;
  }
  &.viewed {
    .h_slideright_menu_inner {
      transform: translateX(0);
    }
    .h_slideright_menu_bg {
      display: block;
    }
  }
}

// =================================================================================
// ヘッダー：TOP用
// =================================================================================
#top {
  .h_wrap:not(.h_fixed) {
    svg {
      fill: #fff;
    }
    .h_inner {
      background-color: transparent;
      .h_blk_left {
        .menu_trigger {
          span {
            background-color: #fff;
          }
        }
      }
      .h_menu {
        nav {
          ul {
            color: #fff;
          }
        }
      }
      .h_blk_right {
        .h_member_wrap {
          .member_name_blk {
            color: #fff;
          }
        }
        .h_resno_wrap {
          border-left: 2px solid #fff;
        }
      }
    }
  }
  //ホバー時の処理
  .h_wrap:not(.h_fixed).hovered {
    svg {
      fill: #000;
    }
    .h_inner {
      background-color: #fff;
      .h_blk_left {
        .menu_trigger {
          span {
            background-color: #000;
          }
        }
      }
      .h_menu {
        nav {
          ul {
            color: #000;
          }
        }
      }
      .h_blk_right {
        .h_member_wrap {
          .member_name_blk {
            color: #000;
          }
        }
        .h_resno_wrap {
          border-left: 2px solid #000;
          .h_resno {
            a {
              svg {
                fill: #0086a2;
              }
            }
          }
        }
      }
    }
  }
  .h_slideright_menu {
    .menu_resno {
      a {
        span {
          svg {
            fill: #fff !important;
          }
        }
      }
    }
  }
}

// =================================================================================
// 帯バナー
// =================================================================================
.h_line_info {
  width: 100%;
  height: auto;
  background-color: #000;
  position: relative;
  z-index: 20;
  .h_line_info_inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: .1em;
    li {
      width: 100%;
      text-align: center;
      position: relative;
      a {
        display: block;
        width: calc(100% - 12px);
        line-height: 1.4;
        padding: 16px 20px 16px 0;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 19px;
        background-image: url(../../img/common/arrow_w.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: absolute;
        top: calc(50% - 10px);
        right: 0;
      }
    }
  }
}
.h_line_bnr {
  width: 100%;
  height: 60px;
  background-color: #D86363;
  position: relative;
  z-index: 20;
  .h_line_bnr_inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    li {
      width: 100%;
      a {
        display: block;
        width: 100%;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1630px) {
.h_wrap {
  //追従メニュー
  &.h_fixed {
    .h_blk_left {
      .h_logo {
        width: 120px;
        height: 32px;
        top: 26px;
      }
    }
    .h_menu {
      margin: 0 auto;
      nav {
        ul {
          font-size: 1.4rem;
          li {
            a {
              padding: 0 15px;
            }
            &.menu_search {
              width: 65px;
              a {
                padding: 0;
              }
            }
          }
        }
      }
    }
    .h_blk_right {
      .h_member_wrap {
        margin-right: 30px;
      }
      .h_favorite_wrap {
        margin-right: 25px;
      }
      .h_cart_wrap {
        margin-right: 27px;
      }
      .h_resno_wrap {
        width: 130px;
        height: 30px;
        .h_resno {
          a {
            svg {
              width: 100px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

// =================================================================================
// 会員系メニュー：ポップアップ
// =================================================================================
.h_fixed {
  .h_member_popup {
    top: 52px;
    right: 196px;
  }

  .h_favorite_popup {
    top: 52px;
    right: 134px;
  }
}
}

@media only screen and (min-width: 1111px) and (max-width: 1220px) {
.h_wrap {
  //追従メニュー
  &.h_fixed {
    .h_blk_left {
      .h_logo {
        width: 120px;
        height: 32px;
        top: 26px;
      }
    }
    .h_menu {
      max-width: 100%;
      margin-left: 216px;
      justify-content: flex-start;
      nav {
        ul {
          font-size: 1.4rem;
          li {
            a {
              padding: 0 15px;
            }
            &.menu_search {
              width: 65px;
            }
          }
        }
      }
    }
    .h_blk_right {
      .h_member_wrap {
        margin-right: 30px;
      }
      .h_favorite_wrap {
        margin-right: 25px;
      }
      .h_cart_wrap {
        margin-right: 27px;
      }
      .h_resno_wrap {
        width: 130px;
        height: 30px;
        .h_resno {
          a {
            svg {
              width: 100px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

// =================================================================================
// 会員系メニュー：ポップアップ
// =================================================================================
.h_fixed {
  .h_member_popup {
    top: 52px;
    right: 196px;
  }

  .h_favorite_popup {
    top: 52px;
    right: 134px;
    .h_menu_popup_btn_close {
      display: none !important;
    }
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
#header {
  //帯バナー
  .h_line_info {
    .h_line_info_inner {
      font-size: 16/1000*100vw;
      padding: 0 40/1000*100vw;
      li {
        a {
          width: 100%;
          padding: 16/1000*100vw 20/1000*100vw 16/1000*100vw 0;
        }
        &::after {
          width: 12/1000*100vw;
          height: 19/1000*100vw;
          top: calc(50% - (10/1000*100vw));
        }
      }
    }
  }
  .h_line_bnr {
    height: auto;
    .h_line_bnr_inner {
      max-width: 100%;
      li {
        width: 100%;
      }
    }
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1110px) {
.h_wrap {
  height: 81px;
  .h_pc {
    display: none;
  }
  .h_sp {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    .h_blk_left {
      .menu_trigger {
        width: 30px;
        height: 35px;
        position: absolute;
        top: 25px;
        left: 30px;
        z-index: 10;
        cursor: pointer;
        span {
          display: block;
          width: 100%;
          height: 5px;
          background-color: #000;
          border-radius: 50px;
          position: absolute;
          left: 0;
          transition-duration: .2s;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 15px;
          }
          &:nth-of-type(3) {
            top: 30px;
          }
        }
      }
      .h_logo {
        width: 150px;
        height: 40px;
        position: absolute;
        top: 22px;
        left: 84px;
        margin: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%
          }
        }
      }
    }
    .h_blk_right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 40px;
      right: 0;
      .h_search_wrap {
        width: 23px;
        height: 21px;
        margin-right: 28px;
        .h_search {
          width: 100%;
          height: 100%;
          &.open {
            a {
              svg {
                fill: #c02d28 !important;
              }
            }
          }
        }
      }
      .h_member_wrap {
        display: flex;
        align-items: center;
        margin-right: 26px;
        .member_name_blk {
          display: block;
          font-size: 1.4rem;
          color: #000;
          font-weight: bold;
          letter-spacing: .025em;
          margin-right: 18px;
          position: absolute;
          top: -30px;
          right: 0;
        }
        .h_member {
          width: 20px;
          height: 22px;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: auto;
            }
            &.open {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_favorite_wrap {
        width: 23px;
        height: 21px;
        margin-right: 23px;
        .h_favorite {
          width: 100%;
          height: 100%;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: none;
            }
            &.open {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_cart_wrap {
        width: 26px;
        height: 22.5px;
        margin-right: 30px;
        .h_cart {
          width: 100%;
          height: 100%;
          position: relative;
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
          .in_cart_item_num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background-color: #c02d28;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            line-height: 1;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            right: -10px;
          }
        }
      }
      .h_resno_wrap {
        display: none;
      }
    }
  }
}
// =================================================================================
// 会員系メニュー：ポップアップ
// =================================================================================
.h_wrap {
  .h_member_popup {
    display: block;
    width: 100%;
    height: 0;
    top: 81px;
    right: 0;
    left: 0;
    padding-top: 0;
    background-color: #fff;
    overflow: hidden;
    transition-duration: .2s;
    ul {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.71;
      color: #000;
      background-color: #fff;
      padding: 4vw;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        a {
          display: block;
          padding: 10px 20px;
          border-bottom: 1px solid #bbb;
        }
      }
    }
    .h_menu_popup_btn_close {
      width: 26vw;
      height: 5.4vw;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 1.8vw;
        letter-spacing: .1em;
        text-align: center;
        line-height: 5.4vw;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 1.8vw;
          height: 1.8vw;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 2vw;
        }
      }
    }
    &::before {
      content: none;
    }
    &.viewed {
      height: 36vw;
    }
  }

  .h_favorite_popup {
    display: block;
    width: 100%;
    height: 0;
    top: 81px;
    right: 0;
    left: 0;
    padding-top: 0;
    background-color: #fff;
    overflow: hidden;
    transition-duration: .2s;
    .popup_favorite_inner {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.71;
      color: #000;
      background-color: #fff;
      padding: 4vw;
      border-radius: 0;
      text-align: center;
      p {
        a {
          display: inline;
        }
      }
      > div {
        width: 40vw;
        height: 7.4vw;
        margin: 0 auto;
        text-align: center;
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 7.4vw;
          font-size: 1.8vw;
          color: #fff;
          border: 1px solid #000;
          background-color: #000;
          letter-spacing: .1em;
        }
      }
    }
    .h_menu_popup_btn_close {
      width: 26vw;
      height: 5.4vw;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 1.8vw;
        letter-spacing: .1em;
        text-align: center;
        line-height: 5.4vw;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 1.8vw;
          height: 1.8vw;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 2vw;
        }
      }
    }
    &::before {
      content: none;
    }
    &.viewed {
      height: 36vw;
    }
  }
}
.h_wrap.h_fixed {
  .h_blk_right {
    height: auto;
  }
  .h_member_popup {
    top: 81px;
    right: 0;
    left: 0;
  }
  .h_favorite_popup {
    top: 81px;
    right: 0;
    left: 0;
  }
}

// =================================================================================
// ヘッダー：スライドダウンメニュー
// =================================================================================
.h_slidedown_menu {
  width: 100%;
  height: 0;
  background-color: #fff;
  position: relative;
  z-index: -1;
  transition-duration: .2s;
  overflow: hidden;
  .h_slidedown_wrap {
    width: 100%;
    height: 100%;
    .h_slidedown_sec {
      width: 100%;
      height: 100%;
      padding: 30px 80px 0;
      display: none;
      overflow: hidden;
      &.viewed {
        display: block;
      }
      &.menu_search {
        .h_slidedown_blk {
          .h_search_blk {
            width: 100%;
            margin: 0 auto;
            form {
              display: block;
            }
            .search_inner {
              display: block;
              .search_input {
                width: 100%;
                border-bottom: 1px solid #8E8A8C;
                padding-left: 10px;
                input[type="text"] {
                  width: 100%;
                  height: 30px;
                  padding: 0 18px 0 0;
                  line-height: 30px;
                  font-size: 16px;
                  outline: none;
                  margin-bottom: 10px;
                }
              }
              .search_btn {
                width: 36vw;
                height: 5.4vw;
                margin: 30px auto;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 2.4vw;
                  height: 2.4vw;
                  background-image: url(../../img/common/ico_search_w.png);
                  background-position: center center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 1.4vw;
                  left: 11vw;
                }
                input[type="submit"] {
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  border: 1px solid #000;
                  color: #fff;
                  font-weight: bold;
                  font-size: 1.8vw;
                  padding-left: 20px;
                }
              }
            }
          }
          .h_keyword {
            width: 100%;
            margin: 0 auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
              margin-right: 15px;
              margin-bottom: 15px;
              a {
                height: 32px;
                line-height: 32px;
                border: 1px solid #000;
                padding: 0 22/750*100vw;
                font-size: 1.4rem;
                font-weight: bold;
                &::before {
                  content: "#";
                  margin-right: .2em;
                }
                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
          .h_menu_popup_btn_close {
            width: 26vw;
            height: 5.4vw;
            margin: 30px auto 0;
            a {
              display: block;
              width: 100%;
              height: 100%;
              background-color: #000;
              color: #fff;
              font-size: 1.8vw;
              letter-spacing: .1em;
              text-align: center;
              line-height: 5.4vw;
              font-weight: bold;
              &::before {
                content: "";
                display: inline-block;
                width: 1.8vw;
                height: 1.8vw;
                background-image: url(../../img/common/close_w.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                margin-right: 2vw;
              }
            }
          }
        }
      }
    }
  }
  &.open {
    height: 380px;
    z-index: 1;
  }
}
// =================================================================================
// スライドライト（ハンバーガー）メニュー
// =================================================================================
.h_slideright_menu {
  .h_slideright_menu_inner {
    padding-bottom: 100px;
    .slideright_menu_top {
      .slideright_menu_3rd {
        ul {
          padding: 0;
          li {
            border-top: 1px solid #777;
            a {
              line-height: 3;
              padding: 0 20px;
            }
            &:nth-of-type(odd) {
              border-right: 1px solid #777;
            }
          }
        }
      }
    }
    .slideright_menu_btn_close {
      width: 200px;
      height: 60px;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 1.6rem;
        letter-spacing: .1em;
        text-align: center;
        line-height: 60px;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 20px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
}

@media only screen and (max-width: 750px) {
.h_wrap {
  width: 100%;
  height: 120/750*100vw;
  .h_pc {
    display: none;
  }
  .h_sp {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    .h_blk_left {
      .menu_trigger {
        width: 36/750*100vw;
        height: 41/750*100vw;
        position: absolute;
        top: 59/750*100vw;
        left: 40/750*100vw;
        z-index: 10;
        cursor: pointer;
        span {
          display: block;
          width: 100%;
          height: 5/750*100vw;
          background-color: #000;
          border-radius: 50px;
          position: absolute;
          left: 0;
          transition-duration: .2s;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 18/750*100vw;
          }
          &:nth-of-type(3) {
            top: 36/750*100vw;
          }
        }
      }
      .h_logo {
        width: 166/750*100vw;
        height: 46/750*100vw;
        position: absolute;
        top: 56/750*100vw;
        left: 112/750*100vw;
        margin-left: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%
          }
        }
      }
    }
    .h_blk_right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 62/750*100vw;
      right: 40/750*100vw;
      .h_search_wrap {
        width: 42/750*100vw;
        height: 38.4/750*100vw;
        margin-right: 44/750*100vw;
        .h_search {
          width: 100%;
          height: 100%;
          &.open {
            a {
              svg {
                fill: #c02d28 !important;
              }
            }
          }
        }
      }
      .h_member_wrap {
        display: flex;
        align-items: center;
        margin-right: 44/750*100vw;
        .member_name_blk {
          color: #000;
          font-weight: bold;
          letter-spacing: .025em;
          position: absolute;
          top: -50/750*100vw;
          right: 0;
          font-size: 22/750*100vw;
          margin-right: 0;
          white-space: nowrap;
        }
        .h_member {
          width: 37/750*100vw;
          height: 40.4/750*100vw;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: auto;
            }
            &.open {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_favorite_wrap {
        width: 44/750*100vw;
        height: 39.5/750*100vw;
        margin-right: 40/750*100vw;
        .h_favorite {
          width: 100%;
          height: 100%;
          .popup_trigger {
            width: 100%;
            height: 100%;
            a {
              display: block;
              width: 100%;
              height: 100%;
              pointer-events: none;
            }
            &.open {
              a {
                svg {
                  fill: #c02d28 !important;
                }
              }
            }
          }
        }
      }
      .h_cart_wrap {
        width: 44/750*100vw;
        height: 38.5/750*100vw;
        margin-right: 20/750*100vw;
        .h_cart {
          width: 100%;
          height: 100%;
          position: relative;
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
          .in_cart_item_num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30/750*100vw;
            height: 30/750*100vw;
            background-color: #c02d28;
            font-size: 16/750*100vw;
            font-weight: 700;
            color: #fff;
            line-height: 1;
            border-radius: 50%;
            position: absolute;
            top: -8/750*100vw;
            right: -17/750*100vw;
          }
        }
      }
      .h_resno_wrap {
        display: none;
      }
    }
  }
  // =================================================================================
  // 追従メニュー
  // =================================================================================
  &.h_fixed {
    height: 100/750*100vw;
    .h_inner {
      .h_blk_left {
        .menu_trigger {
          top: 30/750*100vw;
        }
        .h_logo {
          top: 28/750*100vw;
        }
      }
      .h_menu {
        top: 36/750*100vw;
      }
      .h_blk_right {
        top: 34/750*100vw;
        height: auto;
      }
    }
  }
}

// =================================================================================
// 会員系メニュー：ポップアップ
// =================================================================================
.h_wrap {
  .h_member_popup {
    display: block;
    width: 100%;
    height: 0;
    top: 120/750*100vw;
    right: 0;
    left: 0;
    padding-top: 0;
    background-color: #fff;
    overflow: hidden;
    transition-duration: .2s;
    ul {
      font-size: 26/750*100vw;
      font-weight: bold;
      line-height: 1.71;
      color: #000;
      background-color: #fff;
      padding: 40/750*100vw;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        a {
          display: block;
          padding: 20/750*100vw;
          border-bottom: 1px solid #bbb;
          position: relative;
          &::after {
            content: "";
            display: block;
            background-image: url(../../img/common/arrow_up_b.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            width: 3.86667vw;
            height: 2.53333vw;
            top: calc(50% - 1.5vw);
            right: 20/750*100vw;
          }
        }
      }
    }
    .h_menu_popup_btn_close {
      width: 280/750*100vw;
      height: 75/750*100vw;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 24/750*100vw;
        letter-spacing: .1em;
        text-align: center;
        line-height: 75/750*100vw;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 20/750*100vw;
          height: 20/750*100vw;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 28/750*100vw;
        }
      }
    }
    &::before {
      content: none;
    }
    &.viewed {
      height: 630/750*100vw;
    }
  }

  .h_favorite_popup {
    display: block;
    width: 100%;
    height: 0;
    top: 120/750*100vw;
    right: 0;
    left: 0;
    padding-top: 0;
    background-color: #fff;
    overflow: hidden;
    transition-duration: .2s;
    .popup_favorite_inner {
      font-size: 26/750*100vw;
      font-weight: bold;
      line-height: 1.71;
      color: #000;
      background-color: #fff;
      padding: 40/750*100vw;
      border-radius: 0;
      p {
        text-align: center;
        a {
          display: inline;
        }
      }
      > div {
        width: 480/750*100vw;
        height: 96/750*100vw;
        margin: 0 auto;
        text-align: center;
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 96/750*100vw;
          font-size: 24/750*100vw;
          color: #fff;
          border: 1px solid #000;
          background-color: #000;
          letter-spacing: .1em;
        }
      }
    }
    .h_menu_popup_btn_close {
      width: 280/750*100vw;
      height: 75/750*100vw;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 24/750*100vw;
        letter-spacing: .1em;
        text-align: center;
        line-height: 75/750*100vw;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 20/750*100vw;
          height: 20/750*100vw;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 28/750*100vw;
        }
      }
    }
    &::before {
      content: none;
    }
    &.viewed {
      height: 438/750*100vw;
    }
  }
}
.h_fixed {
  .h_member_popup {
    top: 100/750*100vw;
    right: 0;
    left: 0;
  }

  .h_favorite_popup {
    top: 100/750*100vw;
    right: 0;
    left: 0;
  }
}

// =================================================================================
// ヘッダー：スライドダウンメニュー
// =================================================================================
.h_slidedown_menu {
  width: 100%;
  height: 0;
  background-color: #fff;
  position: relative;
  z-index: -1;
  transition-duration: .2s;
  overflow: hidden;
  .h_slidedown_wrap {
    width: 100%;
    height: 100%;
    .h_slidedown_sec {
      width: 100%;
      height: 100%;
      padding: 56/750*100vw 80/750*100vw;
      display: none;
      overflow: hidden;
      &.viewed {
        display: block;
      }
      &.menu_search {
        .h_slidedown_blk {
          .h_search_blk {
            width: 100%;
            margin: 0 auto;
            form {
              display: block;
            }
            .search_inner {
              display: block;
              .search_input {
                width: 100%;
                border-bottom: 1px solid #8E8A8C;
                padding-left: 10/750*100vw;
                input[type="text"] {
                  width: 100%;
                  height: 50/750*100vw;
                  padding: 0 18/750*100vw 0 0;
                  line-height: 50/750*100vw;
                  font-size: 24/750*100vw;
                  outline: none;
                  margin-bottom: 10/750*100vw;
                }
              }
              .search_btn {
                width: 280/750*100vw;
                height: 76/750*100vw;
                margin: 46/750*100vw auto 60/750*100vw;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 32/750*100vw;
                  height: 32/750*100vw;
                  background-image: url(../../img/common/ico_search_w.png);
                  background-position: center center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 22/750*100vw;
                  left: 54/750*100vw;
                }
                input[type="submit"] {
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  border: 1px solid #000;
                  color: #fff;
                  font-weight: bold;
                  font-size: 24/750*100vw;
                  padding-left: 20/750*100vw;
                }
              }
            }
          }
          .h_keyword {
            width: 100%;
            margin: 0 auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
              margin-right: 26/750*100vw;
              margin-bottom: 26/750*100vw;
              a {
                height: 55/750*100vw;
                line-height: 55/750*100vw;
                border: 1px solid #000;
                padding: 0 22/750*100vw;
                font-size: 24/750*100vw;
                font-weight: bold;
                &::before {
                  content: "#";
                  margin-right: .2em;
                }
                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
          .h_menu_popup_btn_close {
            width: 280/750*100vw;
            height: 75/750*100vw;
            margin: 64/750*100vw auto 0;
            a {
              display: block;
              width: 100%;
              height: 100%;
              background-color: #000;
              color: #fff;
              font-size: 24/750*100vw;
              letter-spacing: .1em;
              text-align: center;
              line-height: 75/750*100vw;
              font-weight: bold;
              &::before {
                content: "";
                display: inline-block;
                width: 20/750*100vw;
                height: 20/750*100vw;
                background-image: url(../../img/common/close_w.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                margin-right: 28/750*100vw;
              }
            }
          }
        }
      }
    }
  }
  &.open {
    height: 642/750*100vw;
    z-index: 1;
  }
}

// =================================================================================
// スライドライト（ハンバーガー）メニュー
// =================================================================================
.h_slideright_menu {
  .h_slideright_menu_inner {
    width: 668/750*100vw;
    padding-bottom: 120px;
    .btn_close {
      width: 36/750*100vw;
      height: 41/750*100vw;
      top: 30/750*100vw;
      left: 40/750*100vw;
      span {
        height: 5/750*100vw;
        &:nth-of-type(1) {
          top: 17/750*100vw;
        }
        &:nth-of-type(3) {
          top: 17/750*100vw;
        }
      }
    }
    .slideright_menu_top {
      width: 100%;
      padding-top: 100/750*100vw;
      .slideright_menu_1st {
        ul {
          li {
            a {
              height: 90/750*100vw;
              padding: 0 48/750*100vw 0 40/750*100vw;
              font-size: 26/750*100vw;
              line-height: 90/750*100vw;
            }
            &.reg_link {
              a {
                &::after {
                  width: 29/750*100vw;
                  height: 19/750*100vw;
                  top: calc(50% - 1.5vw);
                  right: 48/750*100vw;
                }
              }
            }
            &.toggle_link {
              > a {
                &::after {
                  width: 29/750*100vw;
                  height: 19/750*100vw;
                  top: calc(50% - 1.5vw);
                  right: 48/750*100vw;
                }
              }
            }
            &.menu_colantotte {
              a {
                span {
                  width: 106/750*100vw;
                  height: 29/750*100vw;
                  margin-left: 26/750*100vw;
                  top: 6/750*100vw;
                }
              }
            }
            &.menu_resno {
              a {
                span {
                  width: 97/750*100vw;
                  height: 29/750*100vw;
                  margin-left: 16/750*100vw;
                  top: 6/750*100vw;
                }
              }
            }
          }
        }
      }
      .slideright_menu_2nd {
        ul {
          li {
            a {
              padding-left: 70/750*100vw;
            }
          }
        }
      }
      .slideright_menu_3rd {
        ul {
          padding: 0;
          li {
            border-bottom: 1px solid #777;
            a {
              font-size: 20/750*100vw;
              line-height: 4.6;
              padding: 0 30/750*100vw;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              border-top: none;
            }
            &:nth-of-type(odd) {
              border-right: 1px solid #777;
            }
          }
        }
      }
    }
    .slideright_menu_bottom {
      padding: 40/750*100vw 0;
      .slideright_menu_bottom_blk {
        padding-left: 0;
        margin-bottom: 32/750*100vw;
        li {
          width: 300/750*100vw;
          font-size: 20/750*100vw;
          padding-left: 40/750*100vw;
          padding-right: 0;
        }
        &:last-of-type {
          li {
            display: inline-block;
            width: auto;
            height: auto;
            padding-right: 0;
            a {
              width: 100%;
              height: 100%;
              line-height: 1;
              padding: 20/750*100vw;
              font-weight: normal;
              &:hover {
                color: #000;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .slideright_menu_btn_close {
      width: 280/750*100vw;
      height: 75/750*100vw;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        font-size: 24/750*100vw;
        letter-spacing: .1em;
        text-align: center;
        line-height: 75/750*100vw;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 20/750*100vw;
          height: 20/750*100vw;
          background-image: url(../../img/common/close_w.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin-right: 28/750*100vw;
        }
      }
    }
  }
}

// =================================================================================
// 帯バナー
// =================================================================================
.h_line_info {
  .h_line_info_inner {
    font-size: 20/750*100vw;
    padding: 0 40/750*100vw;
    li {
      a {
        width: 100%;
        padding: 16/750*100vw 20/750*100vw 16/750*100vw 0;
      }
      &::after {
        width: 12/750*100vw;
        height: 19/750*100vw;
        top: calc(50% - (10/750*100vw));
      }
    }
  }
}
.h_line_bnr {
  height: auto;
  .h_line_bnr_inner {
    max-width: 100%;
    li {
      width: 100%;
    }
  }
}
}